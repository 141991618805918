/**
 * Returns the condition against which a Typeahead filter will be applied for each search result item
 * @param labelPart     Search result item label part that will be tested
 * @param text          Text entered by user in Typeahead field component
 * @returns             Condition to be checked for Typeahead's filterBy prop (ie a boolean)
 */
const getFilterPredicate = (labelPart: string | undefined | null, text: string) =>
    typeof labelPart === 'string' ? labelPart.toLowerCase().indexOf(text.toLowerCase()) !== -1 : false;

export default getFilterPredicate;
