import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import ItemType from '_types/api/items/ItemType';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import GenderType from '_types/Gender';
import styles from './customIcon.module.scss';

type IconType = ItemType | QuestionnaireType | 'questionnaire' | 'draft' | GenderType;

// ! Note: For now all this component does is pass its own styles via render props to its children.
// However, the plan in the future is to change all the components that are using icons so they
// use this component instead of injecting icons via CSS (background-image and ::before).
type CustomIconProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    extraClassName?: string;
    iconType: IconType;
};

const CustomIcon = ({ extraClassName = '', iconType, ...props }: CustomIconProps) => (
    <span className={`${extraClassName} ${styles.icon} ${styles[iconType]}`} {...props} />
);

export default CustomIcon;
