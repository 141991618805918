import * as yup from 'yup';

export const schema = yup.object().shape({
    searchQuery: yup.string(),
    customQuestionnaire: yup.boolean(),
});

export const initialValues = {
    searchQuery: '',
    customQuestionnaire: false,
};
