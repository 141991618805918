import itemsPath from 'consts/paths/api/items/getItems';
import questionnairesPath from 'consts/paths/api/questionnaires/questionnairesPath';
import CustomIcon from 'elements/CustomIcon';
import { useRouter } from 'next/router';
import { Highlighter } from 'react-bootstrap-typeahead';
import { TypeaheadMenuProps } from 'react-bootstrap-typeahead/types/components/TypeaheadMenu';
import QuestionnaireSearchResult from '_types/api/questionnaires/QuestionnaireSearchResult';
import QuestionnaireType from '_types/api/questionnaires/QuestionnaireType';
import SearchItem from '_types/api/search/SearchItem';
import SearchQuestionnaire from '_types/api/search/SearchQuestionnaire';
import styles from './searchResultDropdown.module.scss';

type SearchResultDropdownProps = {
    option: SearchItem | SearchQuestionnaire;
    menuProps: TypeaheadMenuProps;
};

const SearchResultDropdown = ({ option, menuProps: { text } }: SearchResultDropdownProps) => {
    const router = useRouter();

    if (!option) {
        return null;
    }

    const { object } = option;
    const { code, description, id, type } = object;
    const isGrayQuestionnaire = (object as QuestionnaireSearchResult)?.isCustom;
    const iconType = isGrayQuestionnaire ? QuestionnaireType.PREVIOUS : type;

    const onClickProxy = () => {
        void router.push({
            pathname: option.type === 'item' ? itemsPath : questionnairesPath,
            query: { id },
        });
    };

    return (
        <div onClick={onClickProxy}>
            <CustomIcon extraClassName={styles.option} iconType={iconType} />
            <strong>
                <Highlighter search={text}>{code ? `${code} - ` : ''}</Highlighter>
                {
                    // Items
                    option.type === 'item' ? (
                        <>
                            <Highlighter search={text}>{description ? `${description} - ` : ''}</Highlighter>
                            <Highlighter search={text}>
                                {('preferredWording' in object && object.preferredWording) || ''}
                            </Highlighter>
                        </>
                    ) : (
                        // Questionnaires
                        <Highlighter search={text}>{(object as QuestionnaireSearchResult)?.name || ''}</Highlighter>
                    )
                }
            </strong>
        </div>
    );
};

export default SearchResultDropdown;
