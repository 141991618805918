import usagePath from 'consts/paths/api/usage';
import { usageBaseQueryKey } from 'consts/sharedQueryKeys';
import useApi from 'hooks/useApi';
import { useQuery } from 'react-query';
import Usage from '_types/api/usage';

function useUsage() {
    const api = useApi();

    const {
        data: usageData,
        error,
        isLoading,
    } = useQuery([usageBaseQueryKey], async () => {
        const result = await api.get<Usage>(usagePath);
        return result?.data;
    });

    return { usageData, error, isLoading };
}

export default useUsage;
