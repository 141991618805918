/**
 * Formats given search result item to have a label that starts with the item code
 * @param code    Search result item code (eg: "Q676")
 * @param label   Search result item label to be used for the label
 * @returns       Formatted label
 */
const getOptionLabel = (code: string | undefined | null, label: string | undefined | null) =>
    `${code ? `${code} - ` : ''}${label || ''}`;

export default getOptionLabel;
