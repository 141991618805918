import Field from 'components/_form/Field';
import Warning from 'elements/Warning';
import useForm from 'hooks/useForm';
import { initialValues, schema } from './schema';
import { Col, Container, Form, Row } from 'react-bootstrap';
import formStyles from 'components/_form/form.module.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import styles from './generalSearchPanel.module.scss';
import { Code as Loader } from 'react-content-loader';
import useUsage from 'hooks/useUsage';
import TypeaheadWithApiCall from 'components/_form/TypeaheadWithApiCall';
import SearchItem from '_types/api/search/SearchItem';
import SearchResultDropdown from './SearchResultDropdown';
import itemsPath from 'consts/paths/api/items/getItems';
import { ICDCODES, KEYWORDS } from 'consts/itemDetails';
import { Highlighter } from 'react-bootstrap-typeahead';
import searchResultStyles from './SearchResultDropdown/searchResultDropdown.module.scss';
import { useRouter } from 'next/router';
import { KeyboardEventHandler } from 'react';
import getFilterPredicate from './functions/getFilterPredicate';
import getOptionLabel from './functions/getOptionLabel';

export type SearchPanelFormFields = {
    searchQuery: string;
    customQuestionnaire: boolean;
};

export type SearchFieldColSizes = {
    sm: number;
    md: number;
    lg: number;
};

export type GeneralSearchPanelProps = {
    containerStyles?: string;
    isTitleDisplayed?: boolean;
    searchFieldColSizes?: SearchFieldColSizes;
    isSmall?: boolean;
};

const GeneralSearchPanel = ({
    containerStyles = `${styles['searchPanel--big']} pt-4 pb-5`,
    isTitleDisplayed = true,
    searchFieldColSizes = { sm: 10, md: 8, lg: 6 },
    isSmall = false,
}: GeneralSearchPanelProps) => {
    /*
        Hooks
    */
    const router = useRouter();
    const { usageData, isLoading } = useUsage();
    const {
        register,
        control,
        warningMessage,
        formState: { errors },
        setValue,
        watch,
    } = useForm<SearchPanelFormFields>({
        schema,
        onSubmit: () => Promise.resolve(null),
        defaultValues: initialValues,
    });

    /*
        Variables/constants
    */
    const kind = watch('customQuestionnaire');
    // ! Note: this follows the same logic as what we have on the AS-IS except the "community" path is
    // replaced here by a "kind" query parameter so it matches the query param used in the GET /search
    // endpoint ("kind")
    const customQuestionnairesParam = kind ? undefined : { kind: 'Official' };

    /*
        Event handlers
    */
    const onSelectProxy = (query: string) => {
        void router.push({
            pathname: '/search',
            query: kind ? { q: query } : { q: query, ...customQuestionnairesParam },
        });
    };
    const handleKeypress: KeyboardEventHandler<HTMLFormElement> = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const target = e.target as HTMLInputElement;
            onSelectProxy(target.value);
        }
    };

    return (
        <div className={containerStyles}>
            <Container className={styles.searchPanel}>
                {isLoading ? (
                    <Loader />
                ) : (
                    usageData && (
                        <>
                            {isTitleDisplayed && (
                                <Row>
                                    <Col>
                                        <h2 className={styles.searchPanelTitle}>
                                            {usageData.numberOfQuestionnaires} questionnaires,{' '}
                                            {usageData.numberOfQuestionItems} questions
                                        </h2>
                                    </Col>
                                </Row>
                            )}
                            <Row className={isSmall ? styles.smallSearchWrapper : styles.centred}>
                                <Col
                                    sm={searchFieldColSizes.sm}
                                    md={searchFieldColSizes.md}
                                    lg={searchFieldColSizes.lg}
                                >
                                    <Warning show={!!warningMessage} text={warningMessage} />
                                    <Form
                                        noValidate
                                        className={`${formStyles.form} px-0 pt-0 pb-2`}
                                        onKeyDown={handleKeypress}
                                    >
                                        <Field id="searchQuery" feedback={errors.searchQuery}>
                                            <i className={`bi bi-search ${styles.searchIcon}`} />
                                            <TypeaheadWithApiCall
                                                // Perform 3 calls at once (ideally should be handled in the backend)
                                                apiConfig={[
                                                    {
                                                        url: `${itemsPath}/${KEYWORDS}/search`,
                                                        maxResults: 3,
                                                        params: customQuestionnairesParam,
                                                    },
                                                    {
                                                        url: `${itemsPath}/${ICDCODES}/search`,
                                                        maxResults: 3,
                                                        params: customQuestionnairesParam,
                                                    },
                                                    {
                                                        url: '/search',
                                                        maxResults: 5,
                                                        params: customQuestionnairesParam,
                                                    },
                                                ]}
                                                control={control}
                                                error={errors.searchQuery?.message}
                                                filterBy={(option, props) =>
                                                    getFilterPredicate(
                                                        (option as SearchItem)?.object?.preferredWording,
                                                        props.text,
                                                    ) ||
                                                    getFilterPredicate(
                                                        (option as SearchItem)?.object?.description,
                                                        props.text,
                                                    ) ||
                                                    getFilterPredicate(
                                                        (option as SearchItem)?.object?.code,
                                                        props.text,
                                                    ) ||
                                                    option
                                                }
                                                infoText=""
                                                isInvalid={!!errors.searchQuery}
                                                labelKey={(option) =>
                                                    typeof option === 'string'
                                                        ? option
                                                        : option.type === 'item'
                                                        ? getOptionLabel(
                                                              option.object?.code,
                                                              option.object?.description,
                                                          )
                                                        : getOptionLabel(option.object?.code, option.object?.name)
                                                }
                                                multiple={false}
                                                name="searchQuery"
                                                placeholder="search the item library..."
                                                className={styles.typeahead}
                                                renderMenuItemChildren={(option: SearchItem | string, menuProps) =>
                                                    // Search queries that are not linked to any particular items
                                                    typeof option === 'string' ? (
                                                        <div
                                                            className={searchResultStyles.optionText}
                                                            onClick={() => onSelectProxy(option)}
                                                        >
                                                            <i
                                                                className={`bi bi-search text-secondary ${styles.resultsSearchIcon}`}
                                                            />
                                                            <Highlighter search={menuProps.text}>{option}</Highlighter>
                                                        </div>
                                                    ) : (
                                                        // Items
                                                        <SearchResultDropdown option={option} menuProps={menuProps} />
                                                    )
                                                }
                                                setValue={setValue}
                                            />
                                        </Field>
                                        <Field id="customQuestionnaire" feedback={errors.customQuestionnaire}>
                                            <Form.Check
                                                className={styles.searchPanelCheckbox}
                                                label="Include custom questionnaires from the community"
                                                type="checkbox"
                                                {...register('customQuestionnaire')}
                                                onChange={(value) =>
                                                    setValue('customQuestionnaire', value.target.checked)
                                                }
                                            />
                                        </Field>
                                    </Form>
                                </Col>
                            </Row>
                        </>
                    )
                )}
            </Container>
        </div>
    );
};

export default GeneralSearchPanel;
